<template>
	<div class="signInfo">
		<div class="sign">
			<div class="my-card">
				<div style="">
					<div class="top">
						<span style="font-weight: 600;font-size: 16px;">班级宝贝</span>
						<div class="date">
							<span>{{year}}</span>
							<span class="line"></span>
							<span>{{mouthDay}}</span>
						</div>
					</div>
					<!-- 选择 -->
					<div class="date number" style="margin-top: 20px;">
						<span>总人数: <span style="color: #00aaff">{{TotalCount}}</span>
						</span>
						<span class="line"></span>
						<span>请假: <span style="color: #ffaa00">{{LeaveCount}}</span></span>
					</div>
				</div>

				<!-- 底部宝贝出勤数据 -->
				<div class="babyList">
					<div class="list-box" v-for="item in ChildrenAttendanceDtos" :key="item.ChildrenId">
						<div class="list">
							<van-checkbox v-model="item.checked" @click="singClick"
								:disabled='item.ChildrenAttendanceType? true:false'>
								<van-image width="40" height="40" radius='30%' :src="item.ProfilePicture" class="img"
									style="margin: 0 10px;" />
								<span style="width: 60px;text-align: left;">{{item.ChildrenName}}</span>
							</van-checkbox>
						</div>
						<div class="right" style="color: #a6a6a6;">
							{{item.ChildrenAttendanceType == 1 ? '已签到':item.ChildrenAttendanceType == 0 ? '未签到':'请假'}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部签到 -->
		<div class="signBtn">
			<van-checkbox v-model="allChecked" @click='allSelect' :disabled='allCheckDisabled || ChildrenAttendanceDtos.length == 0'>
				<span>全选</span>
			</van-checkbox>
			<van-button color="#00aaff" plain style="border-radius: 10px;width: 100px;"
				@click="$parent.isShowSign = true">统计</van-button>
			<van-button color="#47AFA7" style="border-radius: 10px;width: 160px" block type="info" @click='signClick'
				:disabled='allCheckDisabled || ChildrenAttendanceDtos.length == 0'>签到
			</van-button>
		</div>
	</div>
</template>

<script>
	import {
		Image as VanImage,
		Checkbox,
		CheckboxGroup,
		Button,
		Toast
	} from 'vant'

	export default {
		data() {
			return {
				year: '',
				mouthDay: '',
				currentDay: '',
				ChildrenAttendanceDtos: [],
				LeaveCount: 0,
				TotalCount: 0,
				checked: false,
				allChecked: false, //全选
				allCheckDisabled: false, //全选是否禁用
			}
		},
		components: {
			[VanImage.name]: VanImage,
			[Checkbox.name]: Checkbox,
			[CheckboxGroup.name]: CheckboxGroup,
			[Button.name]: Button,
			[Toast.name]: Toast
		},
		mounted() {
			this._getChildrenAttendanceList()
			this.currentDate()
		},
		methods: {
			// 宝贝考勤数据
			_getChildrenAttendanceList() {
				this.$axios.get('/api/AttendanceStatistics/childrenAttendanceList').then(res => {
					if (res.code == 200) {
						this.ChildrenAttendanceDtos = res.data.ChildrenAttendanceDtos
						this.LeaveCount = res.data.LeaveCount
						this.TotalCount = res.data.TotalCount
						this.Date = res.Date
						this.ChildrenAttendanceDtos.forEach(item => {
							if (item.ChildrenAttendanceType == 1) {
								this.$set(item, 'checked', true)
							} else {
								this.$set(item, 'checked', false)
							}

						})
						// 数据状态全是已签到,则默认全选禁用
						this.allCheckDisabled = this.ChildrenAttendanceDtos.every(item => {
							return item.ChildrenAttendanceType == 1
						})
					}
				})
			},
			// 当天数据
			currentDate() {
				let data = this.$moment(new Date()).format("YYYY-MM-DD")
				this.year = Number(data.slice(0, 4))
				this.mouthDay = data.slice(5)
				this.currentDay = data
			},
			// 单选
			singClick() {
				let isFlag = this.ChildrenAttendanceDtos.find(item => {
					return item.checked == false
				})
				this.allChecked = isFlag ? false : true

			},
			//全选
			allSelect() {
				this.ChildrenAttendanceDtos.map(item => {
					if (this.allChecked) {
						if(item.ChildrenAttendanceType == 0){
							this.$set(item, 'checked', true)
						}
					} else {
						if(item.ChildrenAttendanceType == 0){
							this.$set(item, 'checked', false)
						}	
					}
				})
			},
			signClick() {
				console.log(this.ChildrenAttendanceDtos)
				let ChildrenIDList = []
				this.ChildrenAttendanceDtos.forEach(item => {
					if (item.checked && !item.ChildrenAttendanceType) {
						ChildrenIDList.push(item.ChildrenId)
					}
				})
				console.log(ChildrenIDList)
				if(ChildrenIDList.length == 0){
					this.$toast.fail('请选择签到的宝贝')
					return
				}
				// 签到接口
				this.$axios.post('/api/AttendanceStatistics/AddChilidrenAttendance', {
					ChildrenIDList
				}).then(res => {
					if (res.code == 200) {
						this.$toast.success(res.msg)
						this._getChildrenAttendanceList()
						this.$parent._getChildrenAttendanceList();
					} else {
						this.$toast.fail(res.msg || '操作失败')
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.signInfo {
		height: 100%;

		.sign {
			height: 80%;
			padding: 0 12px;
		}

		.signBtn {
			position: fixed;
			bottom: 0;
			padding: 16px 0;
			width: 100%;
			background-color: white;
			display: flex;
			align-items: center;
			justify-content: space-around;
		}

	}

	.my-card {
		height: 100%;
		display: flex;
		flex-direction: column;

		.top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #ebedf0;
			padding-bottom: 20px;
		}

		.date {
			height: 15px;
			position: relative;
			line-height: 15px;
			display: flex;
			align-items: center;

			.line {
				display: inline-block;
				width: 1.3px;
				height: 12px;
				background-color: #ebedf0;
				margin: 0 8px;
				position: relative;
				bottom: 1px;
				left: 1px;
			}

			span {
				font-size: 16px;
			}
		}

		.babyList {
			margin-top: 10px;
			flex: 1;
			overflow: scroll;

			.list-box {
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid #ebedf0;

				.list {
					display: flex;
					align-items: center;
					margin: 10px 0;
				}
			}

			/deep/ .van-checkbox__label {
				display: flex;
				align-items: center;
			}
		}

		.number {
			span {
				font-size: 14px !important;
			}
		}
	}
</style>
