<template>
	<div class="parentAttendance">
		<!-- 日数据 -->
		<div class="dayInfo" style="margin-top: 10px">
			<van-calendar title="日历" :poppable="false" :show-confirm="false" style="height: 50vh"
				:show-title='false' @select='selectDay' ref="calendar" :min-date='minDate' :max-date='maxDate' />		
		</div>
	</div>
</template>

<script>
	import {
		Calendar
	} from 'vant'

	export default {
		data() {
			return {
				year: '',
				childerState: '',
				minDate: new Date(new Date().getFullYear()-1, new Date().getMonth(), 1),
				maxDate: new Date()
			}
		},
		components: {
			[Calendar.name]: Calendar,
		},
		mounted() {
			console.log(this.$refs.calendar.currentDate)
			//this.currentDate()
		},
		methods: {
			//选中某天
			selectDay(data) {
				let selecDay = this.$moment(new Date(data)).format("YYYY-MM-DD")
				this.$parent.year = Number(selecDay.slice(0, 4))
				//this.$parent.mouthDay = selecDay.slice(5)
				this.$parent.currentDay = selecDay
				this.$parent._getChildrenAttendanceList()
			},
		}
	}
</script>

<style lang="less" scoped>

</style>
