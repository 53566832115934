<template>
	<!-- 宝贝出勤 -->
	<div class="babyAttence">
		<!-- 考勤组件 -->
		<sign v-if="!isShowSign"></sign>

		<!--  日月统计数据-->
		<div style="height: 100%;padding: 0 12px;" v-else>
			<div class="my-card">
				<!-- 顶部用户数据 -->
				<div class="top">
					<div class="left">
						<span>班级宝贝</span>
					</div>
					<!-- 日历切换 -->
					<div class="right">
						<van-tabs type="card" v-model='value' @change="_getChildrenAttendanceList()">
							<van-tab title="日"></van-tab>
							<van-tab title="月"></van-tab>
						</van-tabs>
					</div>
				</div>
				<!-- 日历数据 -->
				<div class="calendar">
					<div class="date" @click="openMouth">
						<span>{{year}}</span>
						<span class="line"></span>
						<span>{{ value? mouthDay + "月" : currentDay.slice(5)}}</span>
					</div>
					<!-- 日数据组件-->
					<dayInfo ref="dayInfo" v-if="!value"></dayInfo>
					<!-- 月数组件 -->
					<mouthInfo ref="mouthInfo" v-else></mouthInfo>

					<!-- 底部宝贝数据考勤状态数据 -->
					<div class="babyList" style="margin-top: 20px;">
						<!-- 日才展示 -->
						<div class="dateInfo" v-show="!value">
							<span>总人数: <span style="color: #00aaff">{{TotalCount}}</span>
							</span>
							<span class="line"></span>
							<span style="margin: 0 10px;">出勤: <span style="color: #ffaa00">{{AttendanceCount}}</span></span>
							<span class="line"></span>
							<span>请假: <span style="color: #ffaa00">{{LeaveCount}}</span></span>
						</div>
						<div class="bottomList">
							<div class="list-box" v-for="item in ChildrenAttendanceDtos" :key="item.ChildrenId"
								style="margin-top: 10px;">
								<div class="list">
									<van-image width="40" height="40" radius='50%' :src="item.ProfilePicture" class="img"
										style="margin: 0 10px;" />
									<span style="width: 60px;text-align: left;">{{item.ChildrenName}}
							
									</span>
								</div>
							
								<div class="right" style="color: #a6a6a6;" v-show="!value">
									{{item.ChildrenAttendanceType == 1 ? '已签到':item.ChildrenAttendanceType == 0 ? '未签到':'请假'}}
									<van-icon name="arrow" @click="childerDetais(item,{
										dayMonth:1,
										data:currentDay,
									})" />
								</div>
							
								<div class="right" v-show="value" style="color: #a6a6a6;">
									<span>出勤:{{item.AttendanceCount}}</span>
									<span style="margin: 0 10px;">请假:{{item.LeaveCount}}</span>
									<span>休息:{{item.RestCount}}</span>
									<van-icon name="arrow" @click="childerDetais(item,{
										dayMonth:2,
										year,
										mouthDay,
									})" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<selcetMouth ref="selcetMouth" @timeChange='timeChange'></selcetMouth>
	</div>
</template>

<script>
	import {
		Image as VanImage,
		Tab,
		Tabs,
		Calendar,
		Icon
	} from 'vant'

	import sign from './sign/index.vue'
	import dayInfo from './dayInfo/index.vue'
	import mouthInfo from './mouthInfo/index.vue'
	import selcetMouth from '@/components/common/selectMouth/index.vue'

	export default {
		name: 'MyAttence',
		components: {
			dayInfo,
			mouthInfo,
			sign,
			selcetMouth,
			[VanImage.name]: VanImage,
			[Tab.name]: Tab,
			[Tabs.name]: Tabs,
			[Calendar.name]: Calendar,
			[Icon.name]: Icon
		},
		data() {
			return {
				value: 0,
				ProfilePicture: '',
				ChildrenName: '',
				year: '',
				mouthDay: '', //月份
				currentDay: '', //当前日期
				isShowSign: false,
				ChildrenAttendanceDtos: [],
				TotalCount: 0,
				LeaveCount: 0,
				AttendanceCount: 0,
			}
		},
		mounted() {
			this.currentDate()
			this._mouthDate()
			this._getChildrenAttendanceList()
		},
		methods: {
			// 当天数据
			currentDate() {
				let data = this.$moment(new Date()).format("YYYY-MM-DD")
				this.year = Number(data.slice(0, 4))
				this.currentDay = data
			},
			// 月数据(默认当前月)
			_mouthDate() {
				this.year = new Date().getFullYear()
				this.mouthDay = new Date().getMonth() + 1
			},
			// 跳转宝贝考勤详情(携带ChildrenId和时间)
			childerDetais(item, time) {
				this.$router.push({
					path: '/parentAttendance',
					query: {
						id: item.ChildrenId,
						time,
					}
				})
			},
			// 宝贝考勤数据
			_getChildrenAttendanceList() {
				// 月
				let model = {}
				if (this.value) {
					model = {
						dayMonth: 2,
						year: this.year,
						month: this.mouthDay,
					}
				} else {
					// 日
					model = {
						dayMonth: 1,
						date: this.currentDay
					}
				}
				this.$axios.get('/api/AttendanceStatistics/monthAllChildrenStatistics', model).then(res => {
					if (res.code == 200) {
						if (!this.value) {
							this.AttendanceCount = res.data.AttendanceCount
							this.TotalCount = res.data.TotalCount
							this.LeaveCount = res.data.LeaveCount
							this.ChildrenAttendanceDtos = res.data.ChildrenAttendanceDtos
						} else {
							this.ChildrenAttendanceDtos = res.data
						}
					}
				})
			},
			// 选择年月
			openMouth() {
				if (this.value) {
					this.$refs.selcetMouth.show = true
				}
			},
			// 改变年月
			timeChange(time) {
				console.log(time)
				this.year = time.slice(0,4)
				this.mouthDay = Number(time.slice(5))
				this.$refs.mouthInfo.currentIndex = Number(time.slice(5) -1)
				// 刷新数据
				this._getChildrenAttendanceList()
			}
		}
	}
</script>

<style lang="less" scoped>
	.babyAttence {
		height: 100%;
		.my-card {
			.top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #ebedf0;
				padding-bottom: 20px;

				.left {
					display: flex;
					align-items: center;
					font-size: 16px;
					font-weight: 600;
				}
			}

			.calendar {
				margin-top: 20px;
				width: 100%;
				.attendceDay {
					display: flex;
					width: 68%;
					justify-content: space-between;
					padding-left: 10px;
					margin-top: 4px;

					div {
						text-align: center;
						justify-content: space-between;

						p:first-child {
							font-size: 32px;
						}

						p:last-child {
							margin-top: 4px;
						}
					}
				}

				.mouthDay {
					display: flex;
					flex-wrap: wrap;

					.box {
						min-width: 25px;
						height: 25px;
						padding: 10px;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 50%;
					}

					.active {
						background-color: #00aaff;
					}
				}

				.date {
					height: 15px;
					position: relative;
					line-height: 15px;
					display: flex;
					align-items: center;

					.line {
						display: inline-block;
						width: 1.3px;
						height: 12px;
						background-color: #ebedf0;
						margin: 0 8px;
						position: relative;
						bottom: 1px;
						left: 1px;
					}

					span {
						font-size: 16px;
					}
				}
			}

		}

		.babyList {
			margin-top: 10px;
			height: 30vh;
			display: flex;
			flex-direction: column;
			.dataInfo {
				height: 5%;
			}
			.bottomList{
				flex: 1;
				height: 100%;
				overflow: auto;	
				.list-box {
					display: flex;
					justify-content: space-between;
					align-items: center;
					border-bottom: 1px solid #ebedf0;
				
					.list {
						display: flex;
						align-items: center;
						margin: 10px 0;
					}
				}
			}

			/deep/ .van-checkbox__label {
				display: flex;
				align-items: center;
			}

			.number {
				span {
					font-size: 14px !important;
				}
			}
		}
	}
</style>
