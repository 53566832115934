<template>
	<div class="myAttence">
		<!-- 月数据 -->
		<div class="mouthDay" style="margin-top: 10px;">
			<div class="box" v-for="(item,index) in mouthList" :key="item" :class="[currentIndex == index? 'active':'']"
				@click="tabsclick(index)">
				{{item + '月'}}
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Icon
	} from 'vant';

	export default {
		data() {
			return {
				mouthList: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
				currentIndex: Number(new Date().getMonth()),
				//mouthDay: '', //选中的月份
				//year: '',
			}
		},
		components: {
			[Icon.name]: Icon
		},
		mounted() {
			//this._mouthDate()
		},
		methods: {
			// 选中某月
			tabsclick(index) {
				this.currentIndex = index
				this.$parent.mouthDay = index + 1
				this.$parent._getChildrenAttendanceList()
			},
		}
	}
</script>

<style lang="less" scoped>
	.calendar {
		margin-top: 20px;
		width: 100%;

		.mouthDay {
			display: flex;
			flex-wrap: wrap;

			.box {
				min-width: 25px;
				height: 25px;
				padding: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 50%;
			}
		}
		.active {
			background-color: #47AFA7;
		}

		.date {
			height: 15px;
			position: relative;
			line-height: 15px;
			display: flex;
			align-items: center;

			.line {
				display: inline-block;
				width: 1.3px;
				height: 12px;
				background-color: #ebedf0;
				margin: 0 8px;
				position: relative;
				bottom: 1px;
				left: 1px;
			}

			span {
				font-size: 16px;
			}
		}
	}
</style>
